.section6Main h2 {
    text-align: center;
    font-size: 2.8em;
    font-weight: 900;
    letter-spacing: 5px;
    color: #031F57;
}

.section6Down{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.section6{
    display: flex;
    flex-direction: column;
    gap:40px
}

.Column2 img{
    width: 100%;
    max-width: 500px;
    object-fit: contain;
    height: 100%;
}

.Column1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #f5f5f5;
    align-items: start;
    padding: 5% 12% 0;
    gap: 20px;
}

.section6Child{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.5rem;
    justify-content: center;
    align-items: center;
    /* border: 1px solid black; */
}

.ColumnsMain{
    display: grid;
    grid-template-columns: 50% 50%;  
    /* height: 48vh;  */
}

.btn4{
    background-color: #ffb200;
    border: none;
    padding: 10px 30px 10px 30px;
    border-radius: 20px;
}

.Column1 p{
    font-weight: 300;
    font-size: 0.75em;
}

.btn6{
    background-color: #ffb200;
    border: none;
    margin-top:7%;
    padding: 20px 30px 20px 30px;
    border-radius: 10px;
}




@media (max-width: 1200px) {
    .ColumnsMain {
        display: grid;
        grid-template-columns: 50% 50%;
        height: 30vh;
    }
    .Column2 img {
        width: 100%;
        max-width: 500px;
        object-fit: cover;
        height: 100%;
    }
    .section6Child{
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 4rem;
        justify-content: center;
        align-items: center;
    }
}