.section5Main h2 {
    text-align: center;
    font-size: 2.8em;
    font-weight: 900;
    letter-spacing: 5px;
    color: #031F57;
}

.slider-container {
    overflow: hidden;
}

.slick-wrapper {
    width: 100%;
    overflow: hidden;
}

.slider-container {
    max-width: 1200px;
    margin: 0 auto;
}

.brand-slide {
    padding: 10px;
}

.brand-slide img {
    width: 100%;
    display: flex;
    gap: 0;
    max-width: 180px;
}


@media (max-width: 1000px) {

     .section5Main h2 {
         text-align: center;
         font-size: 2em;
         font-weight: 900;
         letter-spacing: 2px;
         color: #031F57;
     }

    
}