/* Contact.css */

.contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.contact-info {
    width: 100%;
    max-width: 500px;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.contact-detail {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.contact-icon {
    margin-right: 10px;
}

.contact-detail span {
    font-size: 16px;
}

.contact-form {
    width: 100%;
    max-width: 500px;
    background-color:#ffb200;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    color: #333;
}

.form-input,
.form-textarea {
    width: 100%;
    padding: 10px 0px 10px 0px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
}

.form-input:focus,
.form-textarea:focus {
    border-color: #007bff;
    outline: none;
}

.form-textarea {
    height: 100px;
    resize: vertical;
}

.form-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
}

.form-button:hover {
    background-color: #0056b3;
}

.form-message {
    margin-top: 20px;
    padding: 10px;
    border-radius: 4px;
    font-size: 16px;
    text-align: center;
}

.form-message.success {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
}

.form-message.error {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
}