.section3Main{
    display: flex;
    justify-content: center;
    gap: 100px;
    text-align: center;
    padding: 35px 0px 35px 0px;
    /* background-color: #; */
    /* border: 2px solid black; */
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
}

.Child2{
    text-transform:uppercase;
    letter-spacing: 1px;
    color: #031f57;
    text-align: center;
}

.Child1{
    color: #f97316;
    text-align: center;
}

.section3First{
    border-right: 2px solid black;
    border-left: 2px solid black;
    padding-left: 20px;
    transition: background-color 0.3s ease-in;
}


.section3Second{
    border-right: 2px solid black;
    transition: background-color 0.3s ease-in;
}

.section3Third{
    border-right: 2px solid black;
    transition: background-color 0.3s ease-in;
    padding-right: 20px;
}

@media (max-width: 1000px) {
    .section3Main {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 5px;
        text-align: center;
        padding: 35px 20px 35px 20px;
        /* border: 2px solid black; */
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
    }

    .section3First {
        /* border-right: 2px solid black;
        border-left: 2px solid black;
        border-top:2px solid black;
        border-bottom:2px solid black; */
        /* padding-left: 20px; */

        border: none;
        transition: background-color 0.3s ease-in;
        background-color: #F5F5F5;
        padding: 20px 0px 20px 0px;
    }

    .section3Second {
        /* border-right: 2px solid black;
        border-left: 2px solid black;
        border-top: 2px solid black;
        border-bottom: 2px solid black; */
        border: none;
        padding: 20px 0px 20px 0px;
        transition: background-color 0.3s ease-in;
        background-color: #F5F5F5;
    }

    .section3Third {
        /* border-right: 2px solid black;
        border-left: 2px solid black;
        border-top: 2px solid black;
        border-bottom: 2px solid black; */
        border: none;
        transition: background-color 0.3s ease-in;
        /* padding-right: 20px; */
        padding: 20px 0px 20px 0px;
        background-color: #F5F5F5;
    }
}