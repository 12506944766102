.HomeMain{
    display: flex;
    flex-direction: column;
    gap: 100px;
}

@media (max-width: 1000px) {
    .HomeMain {
        display: flex;
        flex-direction: column;
        gap: 45px;
    }
}