/* Navbar Title Top Section */
.NavTop{
    display: flex;
    justify-content: center;
    align-items: center;
}

.mobilenumber{
    text-decoration: none;
    color: white;
}

.NavTopLeftContent,
.NavTopRightContent {
    flex: 1;
}

.NavTopRightContent{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    background-color: #001e57;
    color: #fff;
    padding: 10px 0px 10px 0px;
}

.NavTopLeftContent{
    display: flex;
    justify-content: end;
    align-items: center;
    background-color: #031331;
    color: #fff;
    font-weight: 300;
    padding: 10px 0px 10px 0px;
}

.NavTopLeftContent h4{
    margin-right: 20px;
}

.NavTopRightContent div{
    display: flex;
    gap: 10px;
    align-items: center;
}

.NavTopLeftContent h4{
    color: #fff;
    font-weight: 500;
}

.NavIcons{
    color: #ffb200;
}

/* Navabar Second Section */

.navmain{
    display: flex;
    justify-content: center;
    background-color: #F5F5F5;
}
.NavBottom{
    display: flex;
    align-items: center;
    gap: 350px;
    /* width: 90%; */
}

.LogoSection{
    display: flex;
    /* width: 45%; */
}

.NavigationSection{
    display: flex;
    /* width: 55%; */
}

.LogoSection img{
    width: 100%;
    max-width: 130px;
}

.NavNavigation ul{
    display: flex;
    gap:50px;
}

.NavNavigation ul li{
    list-style: none;
    font-weight: 700;
    font-size: 1.2rem;
    cursor: pointer;
}

.NavNavigation ul li:hover{
    color:#df6512 ;
}

.NavigationSection{
    display: flex;
    align-items: center;
    gap: 70px;
}

.btn{
    /* background-color: #df6512; */
    background-color: #ffb200;
    color: #000;
    font-weight: bolder;
    border: none;
    padding: 18px 25px 18px 25px;
    border-radius: 5px;
}

.drop{
    display: flex;
    cursor: pointer;
    align-items: center;
    gap: 5px;
}

.drop .dropIcon {
    transition: transform 0.3s ease-in-out;
}

.drop:hover .dropIcon {
    transform: rotate(-180deg);
}

.drop:hover .dropMenu{
    display: block;
}

.dropMain{
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 20px;
}
.dropMenu ul{
    display: flex;
    gap: 08px;
    flex-direction: column;
}

.dropMenu{
    position: absolute;
    /* display: none; */
    z-index: 10;
    top: 25px;
    left: 50%;
    transform: translateX(-50%);
    min-width: 250px;
    background-color: #ced8e2;
    border-radius: 5px;
    color: #000;
    padding: 15px 10px 10px;
    /* opacity: 0; */
    transition: display 0.3s ease-in-out;
}

.drop .dropIcon.rotated {
    transform: rotate(-180deg);
}

.dropUl li{
   font-size: 0.9em;
   font-weight: 200;
   border-bottom: 1px solid black;
}

.NavNavigation ul li a{
    text-decoration: none;
    color: #000;
}

.NavNavigation ul li a:hover{
    color: #ffb200;
}

.menuIcons{
    display: none;
}

@media (max-width: 1000px) {

.menuIcons {
        display: block;
    }
    .NavTop {
        display: flex;
        flex-direction: column;
    }

    .NavTopLeftContent {
        width: 100%;
    }

    .NavTopLeftContent h4 {
        color: #fff;
        font-size: 0.8em;
        text-align: center;
        font-weight: 500;
    }

    .NavTopRightContent {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
    }


    .NavigationSection {
        position: absolute;
        top:0;
        background-color: aqua;
        /* right: -100%;*/
        visibility: hidden;
        display: flex;
        transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
        /* transform: translateX(100%); */
        /* width: 0; */
        /* overflow: hidden; */
        flex-direction: column;
    }

    .LogoSection img {
        width: 70%;
    }

    .NavNavigation ul { 
        display: flex;
        flex-direction: column;
    }

    .navmain {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        /* overflow: hidden; */
        justify-content: space-between;
        align-items: center;
        background-color: #f5f5f5;
    }

    .menuIconsChild {
        position: absolute;
        right: 0;
    }

    .menuOpen {
        position: absolute;
        right: 20px;
        top: -15px;
        font-size: 30px;
    }

    .menuClose {
        position: absolute;
        z-index: 99;
        right: 30px;
        top: -15px;
        font-size: 30px;
    }

    .NavBottom {
        /* position: relative; */
        display: flex;
        align-items: center;
        gap: 50px;
        overflow: hidden;
    }

    .NavigationSection.open {
        /* transform: translateX(0%); */
        /* right: 0; */
        text-align:center;
        z-index: 99;
        /* opacity: 1; */
        padding: 30px 0px 30px 0px;
        width: 100%;
        visibility: visible;
        overflow: hidden;
        /* Adjust the width as needed */
    }
}