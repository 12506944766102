.mainSection4{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.section4{
    display: flex;
    flex-direction: row;
    width: 90%;
    justify-content: center;
    align-items: center;
}

.section4First{
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: start;
}

.section4First h2{
    font-size:3em;
    font-weight: 900;
    color: #031F57;
}

.section4First p{
    width: 80%;
    font-weight: 700;
    line-height: 25px;
    text-align: justify;
}

.dividerLine{
    border-bottom: 2px solid #FFB200;
}

.section4Second img{
    border-radius: 10px 10px 100px 10px;
}

.btn3{
    background-color: #FFB200;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    padding: 20px 40px 20px 40px;
    color: black;
    font-weight: bold;
}


@media (max-width: 1000px) {

    .section4 {
            display: flex;
            flex-direction: column-reverse;
            width: 90%;
            gap: 20px;
            justify-content: center;
            align-items: center;
        }
    
    .section4Second img{
        width: 100%;
        display: none;
        height: 40vh;
        border-radius: 0px 0px 0px 0px;
        object-fit: contain;
    }

    .section4First {
        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: center;
        align-items: center;
    }

    .section4First p {
        width: 100%;
        font-weight: 500;
        line-height: 22px;
        text-align:justify;
    }
}