.section7{
    padding: 40px 0px 50px 0px;
    background-color: #f5f5f5;
}

.section7Children{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    height: 15vh;   
}

.section7MAin{
    display: grid;
    /* justify-content: center; */
    /* align-items: center; */
    grid-template-columns: 1fr 1fr 1fr;
}

.Icons{
    font-size: 20px;
    border-radius: 50%;
    border: 1px solid #000;
    display: inline-block;
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
}

.section7Children h3{
    background-color: #fff;
    width:250px;
    text-align: center;
    line-height: 60px;
    height: 50vh;
}

@media (max-width: 1000px) {
    .section7MAin {
        display: grid;
        /* justify-content: center; */
        /* align-items: center; */
        gap: 50px;
         grid-template-columns: 1fr;
    }

    .section7 {
        /* padding: 40px 0px 50px 0px; */
        background-color: #f5f5f5;
    }

    .section7Children {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }
}